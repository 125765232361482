
import { Product } from '@/store/models/Product';
import ProductStore from '@/store/modules/ProductStore';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';

@Component({
  computed: {
    currentProduct: () => ProductStore.currentProduct ?? undefined,
    currentEntityIndex: () => ProductStore.currentEntityIndex ?? -1
  }
})
export default class EntitySidebarHeader extends mixins(AppName) {
  @Prop() entityTitles!: {
    title: string;
    subtitle: string;
    secondSubtitle: string;
  };
  @Prop() size!: 'small' | 'large';
}
