
import EntitySideContent from '@/components/sidebar/EntitySideContent.vue';
import SiteSideContent from '@/components/sidebar/SiteSideContent.vue';
import SettingsSideContent from '@/components/sidebar/SettingsSideContent.vue';
import { Product } from '@/store/models/Product';
import { SelectedRoute } from '@/utils/classes/SelectedRoute';
import { Constants } from '@/utils/Constants';
import { productType } from '@/utils/types/ProductType';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';
import SidebarHeader from './SidebarHeader.vue';
import { isAdmin } from '@/utils/TestingValidity';
import { trackEvent } from '@/services/Mixpanel';

@Component({
  components: {
    EntitySideContent,
    SiteSideContent,
    SidebarHeader,
    SettingsSideContent
  },
  methods: {
    isAdmin
  }
})
export default class SideContent extends mixins(AppName, ConstantsMixin) {
  @Prop() selectedEntityState!: Product;
  @Prop() selected!: boolean;
  @Prop() entityIndex!: number;
  @Prop() width!: number;
  @Prop() hideClose!: boolean;
  public tabIndex = 0;
  public route = new SelectedRoute(this.getAppName(false) as productType);
  public partnerDefinedName = '';
  public partnerDefinedId = '';
  public isInRoute = false;

  created() {
    this.$root.$on('switchToSettingsTab', () => {
      this.tabIndex = 2;
    });
    this.$root.$on('routeUpdated', () => {
      this.route.updateChecked();
      this.updateIsInRoute();
    });
  }

  mounted() {
    this.updateIsInRoute();
  }

  @Watch('tabIndex')
  changedTab() {
    this.$root.$emit('tabChanged', this.tabIndex);
    trackEvent(
      `User changed to ${
        [
          this.getEntityName(false, this.selectedEntityState),
          'site',
          'settings'
        ][this.tabIndex]
      } tab on ${this.getAppName(false)} product`,
      {
        productId: (this.selectedEntityState?.productId ?? 0).toString()
      }
    );
    this.updateIsInRoute();
  }

  @Watch('entityIndex')
  changedEntity() {
    this.updateIsInRoute();
  }

  @Watch('selectedEntityState')
  changeInProduct() {
    this.partnerDefinedName =
      this.selectedEntityState?.partnerDefinedName ?? '';
    this.partnerDefinedId = this.selectedEntityState?.partnerDefinedId ?? '';
    this.updateIsInRoute();
  }

  get currentEntity(): Product | null {
    if (this.selectedEntityState && this.selectedEntityState.entities) {
      return this.selectedEntityState.entities[this.entityIndex];
    } else {
      return null;
    }
  }

  public updateIsInRoute() {
    if (this.selectedEntityState) {
      this.isInRoute = this.route.isInRoute(
        'productChecked-' +
          this.selectedEntityState.productId +
          '-' +
          (this.tabIndex == 0 ? this.currentEntity?.entityId : '0000')
      );
    } else {
      return false;
    }
  }

  public updateRoute() {
    let updatedAlert = '';
    if (this.tabIndex == 1) {
      // On site tab so add whole site to route
      if (this.isInRoute) {
        updatedAlert = this.route.removeAllEntitesFromChecked(
          this.selectedEntityState.productId.toString()
        );
      } else {
        updatedAlert = this.route.addAllEntitesToChecked(
          this.selectedEntityState.productId.toString()
        );
      }
    } else {
      updatedAlert = this.route.addToRoute(
        'productChecked-' +
          this.selectedEntityState.productId +
          '-' +
          this.currentEntity?.entityId
      );
    }

    this.$bvToast.toast(updatedAlert, {
      title: 'Route updated',
      toaster: 'b-toaster-bottom-center',
      solid: true,
      append: false,
      to: `/${this.getAppName(false)}-route-plan`
    });
    this.$root.$emit('routeUpdated');
  }

  public updatedLabels(name: string, id: string) {
    this.partnerDefinedName = name;
    this.partnerDefinedId = id;
  }

  public closeSidebar() {
    this.$emit('closeSidebar');
  }

  public getEntityImage() {
    switch (this.getAppName(false)) {
      case Constants.PRODUCT_TYPE_FUEL:
        return require(`../../assets/fuel-grey.svg`);
      case Constants.PRODUCT_TYPE_FEED:
        return require(`../../assets/silo-volume-grey.svg`);
      default:
        return require(`../../assets/milk-vat-grey.svg`);
    }
  }
}
