
import FormDropdownInput from '@/components/common/FormDropdownInput.vue';
import FormTextInput from '@/components/common/FormTextInput.vue';
import { UpdateEntityCustomFieldData } from '@/services/api/CustomFieldApi';
import { trackEvent } from '@/services/Mixpanel';
import CustomField from '@/store/models/CustomField';
import CustomFieldData from '@/store/models/CustomFieldData';
import { Product } from '@/store/models/Product';
import CustomStore from '@/store/modules/CustomStore';
import ProductStore from '@/store/modules/ProductStore';
import User from '@/store/modules/User';
import { generateCustomFieldDisplayData } from '@/utils/CustomFields';
import cloneDeep from 'lodash.clonedeep';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';

@Component({
  components: {
    FormTextInput,
    FormDropdownInput
  }
})
export default class CustomFieldDataList extends mixins(AppName) {
  @Prop() siteView!: boolean;
  @Prop() currentEntity?: Product | null;

  public customFieldData: CustomFieldData[] = [];
  public editCustomFieldData: CustomFieldData[] = [];
  public updated = false;

  get currentProduct(): Product | null {
    return ProductStore.currentProduct ?? null;
  }

  get customFields(): CustomField[] | null {
    return (
      (this.siteView
        ? CustomStore.customFields
        : CustomStore.customEntityFields) ?? null
    );
  }

  mounted() {
    this.loadCustomFields();
  }

  @Watch('currentEntity')
  @Watch('currentProduct')
  changeInProduct() {
    this.updated = false;
    this.loadCustomFields();
  }

  public newInput(newValue: string) {
    this.updated = true;
  }

  public loadCustomFields() {
    this.customFieldData = generateCustomFieldDisplayData(
      this.currentProduct,
      this.currentEntity ?? null,
      this.siteView,
      this.customFields
    );
  }

  public submitUpdateData() {
    if (!this.siteView && this.currentEntity) {
      UpdateEntityCustomFieldData({
        partnerId: User.orgId,
        entityType: this.getEntityApiWord(),
        entityId: +(this.currentEntity.entityId ?? 0),
        data: this.customFieldData
      }).then(() => {
        trackEvent(
          `User added custom data to ${
            this.siteView ? 'products' : this.getEntityApiWord()
          }`
        );
        this.$root.$emit('customFieldsUpdated');
        this.updated = false;
      });
    } else if (this.siteView) {
      UpdateEntityCustomFieldData({
        partnerId: User.orgId,
        entityType: 'products',
        entityId: this.currentProduct?.productId ?? 0,
        data: this.customFieldData
      }).then(() => {
        trackEvent(
          `User added custom data to ${
            this.siteView ? 'products' : this.getEntityApiWord()
          }`
        );
        this.$root.$emit('customFieldsUpdated');
        this.updated = false;
      });
    }
  }
}
