
import { ComplianceSettings } from '@/store/models/MilkScore';
import { MilkMetrics, Product } from '@/store/models/Product';
import ProductStore from '@/store/modules/ProductStore';
import { getComplianceScore } from '@/utils/MilkScoreUtils';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';

@Component
export default class MilkScoreTooltip extends mixins(AppName) {
  @Prop() scaleWidth!: number;
  @Prop() thresholdRange!: number[];
  @Prop() colour!: string;
  public test = 0;
  public offset = 0;
  public show = true;

  mounted() {
    this.updateScorePosition();
    this.$root.$on('tabChanged', (tab: number) => {
      if (tab == 0) {
        this.show = true;
      } else {
        this.show = false;
      }
    });
  }

  get currentEntity(): Product | null {
    return ProductStore.currentEntity ?? null;
  }

  get currentScore() {
    return getComplianceScore(
      (this.currentEntity?.metrics as MilkMetrics).milkScores,
      this.currentEntity?.entityCustomSettingsData as ComplianceSettings
    );
  }

  get currentAreaOutOfSpec() {
    return this.currentScore?.areaOutOfSpec ?? 0;
  }

  public updateScorePosition() {
    if (this.thresholdRange[1] != 0) {
      if (this.currentAreaOutOfSpec > this.thresholdRange[1]) {
        this.offset = this.scaleWidth / 2;
      } else {
        //Finding the point in the threshold block, e.g. a value of 0.38 means the point is 38% of the way along the block
        const decimalAcrossInBlock =
          ((this.currentAreaOutOfSpec ?? 0) - this.thresholdRange[0]) /
          (this.thresholdRange[1] - this.thresholdRange[0]);
        //Calculating the number of pixels across, e.g. width of block is 150px, and it's 38% of the way, this value would be 57 as it is 57px from the start of the block
        const pixelsAcrossInBlock = decimalAcrossInBlock * this.scaleWidth;
        //This number is the offset from the center, number will be negative if it is before the halfway poiint and positive if after
        this.offset = pixelsAcrossInBlock - this.scaleWidth / 2;
      }
    } else {
      //Position at the very start of the block
      this.offset = -(this.scaleWidth / 2);
    }
  }

  @Watch('currentEntity')
  entityUpdated() {
    this.updateScorePosition();
  }
}
