
import BurndownActivityGraph from '@/components/graphs/BurndownActivityGraph.vue';
import BurndownActivityGraphModal from '@/components/modal/BurndownActivityGraphModal.vue';
import { Product } from '@/store/models/Product';
import { PlaceholderData } from '@/utils/classes/PlaceholderData';
import { getDayjsFromUnix, getToday } from '@/utils/DateUtils';
import { productType } from '@/utils/types/ProductType';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import SidebarCard from '../common/SidebarCard.vue';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';

@Component({
  components: {
    BurndownActivityGraph,
    BurndownActivityGraphModal,
    SidebarCard
  },
  methods: { getDayjsFromUnix, getToday }
})
export default class BurndownActivityGraphCard extends mixins(
  AppName,
  ConstantsMixin
) {
  @Prop() currentEntity!: Product;
  @Prop() title!: string;
  @Prop() isNotLive!: boolean;

  getPlaceholderEntity() {
    return new PlaceholderData(
      this.getAppName(false) as productType,
      this.currentEntity
    ).product;
  }
}
