
import BurndownActivityGraphCard from '@/components/cards/BurndownActivityGraphCard.vue';
import UsageGraphCard from '@/components/cards/UsageGraphCard.vue';
import ProductStore from '@/store/modules/ProductStore';
import { Constants } from '@/utils/Constants';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import VolumeScoreGraphCard from '../cards/VolumeScoreGraphCard.vue';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';

@Component({
  components: {
    UsageGraphCard,
    BurndownActivityGraphCard,
    VolumeScoreGraphCard
  },
  computed: {
    currentEntity: () => ProductStore.currentEntity ?? undefined
  }
})
export default class EntityGraphsGroup extends mixins(AppName, ConstantsMixin) {
  @Prop() title!: string;
  @Prop() isNotLive!: boolean;

  public getAppGraphIds() {
    switch (this.getAppName(false)) {
      case Constants.PRODUCT_TYPE_MILK:
        return ['volumeScoreGraph'];
      case Constants.PRODUCT_TYPE_FUEL:
        return ['burndownGraph', 'comparisonBarGraph'];
      case Constants.PRODUCT_TYPE_FEED:
        return ['burndownGraph', 'comparisonBarGraph'];
      case Constants.PRODUCT_TYPE_WATER:
        return ['comparisonBarGraph'];
      default:
        return [];
    }
  }
}
