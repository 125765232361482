
import FuelActivityList from '@/components/list/FuelActivityList.vue';
import { Product } from '@/store/models/Product';
import dayjs from 'dayjs';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';
import ActivityDatePickerModal from '@/components/modal/ActivityDatePickerModal.vue';
import ProductStore from '@/store/modules/ProductStore';
import SidebarCard from '../common/SidebarCard.vue';
import FeedRefillsList from '../list/FeedRefillsList.vue';
import ConstantsMixin from '../mixin/Constants.vue';
import { trackEvent } from '@/services/Mixpanel';

@Component({
  components: {
    FuelActivityList,
    ActivityDatePickerModal,
    SidebarCard,
    FeedRefillsList
  },
  computed: {
    currentEntity: () => ProductStore.currentEntity ?? undefined
  }
})
export default class ActivityListCard extends mixins(AppName, ConstantsMixin) {
  @Prop() title!: string;
  public activityDays: number | null = this.isApp(this.PRODUCT_TYPE_FEED)
    ? 30
    : 7;
  public customActivityDates = '';
  public defaultDays = { fuel: [7, 14, 30], feed: [30, 60, 90] };

  created() {
    this.activityDays = this.isApp(this.PRODUCT_TYPE_FEED) ? 30 : 7;
    this.$root.$on(
      'customActivityDaysSelected',
      this.customActivityDatesSelected
    );
  }

  public changeActivityDays(days: number) {
    if (days == null) {
      this.showActivityDatePickerModal();
    } else {
      trackEvent(
        `User changed days on ${this.getAppName(false)} activity list`,
        {
          days: days.toString()
        }
      );
      this.customActivityDates = '';
      this.activityDays = days;
      this.$root.$emit(
        'activityUpdated',
        dayjs()
          .subtract(days, 'day')
          .unix()
          .toString(),
        dayjs()
          .unix()
          .toString()
      );
    }
  }

  public customActivityDatesSelected(
    from = dayjs().subtract(this.activityDays || 7, 'day'),
    to = dayjs()
  ) {
    this.customActivityDates =
      from.format('D MMM YY') + ' - ' + to.format('D MMM YY');
    this.activityDays = null;
    trackEvent(`User changed days on ${this.getAppName(false)} activity list`, {
      customDays: from.format('D MMM YY') + ' - ' + to.format('D MMM YY')
    });
  }

  public showActivityDatePickerModal() {
    this.$root.$emit('bv::show::modal', 'activityDatePickerModal', '#btnShow');
  }

  public closeActivityDatePickerModal() {
    this.$root.$emit('bv::hide::modal', 'activityDatePickerModal', '#btnHide');
  }
}
