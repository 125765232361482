
import MetricTile from '@/components/common/MetricTile.vue';
import { FeedMetrics, FuelMetricsTank } from '@/store/models/Product';
import ProductStore from '@/store/modules/ProductStore';
import {
  checkFuelFeedMetricUnavailable,
  checkMilkMetricUnavailable,
  checkWaterMetricUnavailable,
  formatAgeOfMilkDateDisplay,
  formatAgeOfMilkDisplay,
  formatCurrentUsageDisplay,
  formatDailyUseDateDisplay,
  formatDailyUseDisplay,
  formatDaysTillEmptyDateDisplay,
  formatDaysTillEmptyDisplay,
  formatFeedDailyUseDisplay,
  formatFeedVolumeDisplay,
  formatFillVolumeDisplay,
  formatLastCollectionDateDisplay,
  formatLastCollectionDisplay,
  formatLastRefillTileDisplay,
  formatLastRefillVolumeDisplay,
  formatMilkVolumeDisplay,
  formatPercentFullDisplay,
  formatUsageTodayDisplay,
  formatUsageYesterdayDisplay,
  formatVolumeDisplay,
  isLive
} from '@/utils/formatters/DisplayValueFormatters';
import dayjs from 'dayjs';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';

@Component({
  components: { MetricTile }
})
export default class MetricTileGroup extends mixins(AppName, ConstantsMixin) {
  @Prop() width!: number;

  get currentEntity() {
    return ProductStore.currentEntity ?? undefined;
  }

  get milkTileDetails() {
    if (!this.currentEntity) return [];
    return [
      {
        tileElements: {
          title: `In the ${this.getEntityName(false)}`,
          value: formatMilkVolumeDisplay(this.currentEntity),
          subtitleValue: `As at ${this.relativeDay(dayjs().unix())}`,
          icon: 'vat',
          isLeftTile: true,
          disabledMessage: 'No data available to display'
        },
        disabled:
          !isLive(this.currentEntity) ||
          checkMilkMetricUnavailable(this.currentEntity, 'vatVolume'),
        classAlterations: {
          borderVariant:
            (this.currentEntity.metrics?.alert ?? 0) > 0 ? 'danger' : 'none'
        }
      },
      {
        tileElements: {
          title: 'Last collection',
          value: formatLastCollectionDisplay(this.currentEntity),

          subtitleValue: formatLastCollectionDateDisplay(this.currentEntity),
          icon: 'time',
          isLeftTile: false,
          disabledMessage: 'No data available to display'
        },
        disabled:
          !isLive(this.currentEntity) ||
          checkMilkMetricUnavailable(this.currentEntity, 'lastPickup')
      },
      {
        tileElements: {
          title: 'Age of milk',
          value: formatAgeOfMilkDisplay(this.currentEntity),
          subtitleValue: formatAgeOfMilkDateDisplay(this.currentEntity),
          icon: 'milking',
          isLeftTile: null,
          disabledMessage: 'No data available to display'
        },
        disabled:
          !isLive(this.currentEntity) ||
          checkMilkMetricUnavailable(this.currentEntity, 'milkEnteredVat')
      }
    ];
  }

  get feedFuelTileDetails() {
    if (!this.currentEntity) return [];
    return [
      {
        tileElements: {
          title: `In the ${this.getEntityName(false, this.currentEntity)}`,
          value: formatPercentFullDisplay(this.currentEntity),
          secondaryValue: this.isApp(this.PRODUCT_TYPE_FUEL)
            ? formatVolumeDisplay(this.currentEntity)
            : formatFeedVolumeDisplay(this.currentEntity),
          subtitleValue: `As at ${this.relativeDay(
            (this.currentEntity.metrics as FuelMetricsTank | FeedMetrics)
              .percentFullTs || 0
          )}`,
          icon: 'tank',
          isLeftTile: true,
          disabledMessage: 'No data available to display'
        },
        disabled:
          !isLive(this.currentEntity) ||
          checkFuelFeedMetricUnavailable(this.currentEntity, [
            'percentFull',
            'volume'
          ]),
        classAlterations: {
          borderVariant:
            (this.currentEntity.metrics?.alert ?? 0) > 0 ? 'danger' : 'none'
        }
      },
      {
        tileElements: {
          title: this.isApp(this.PRODUCT_TYPE_FUEL) ? 'Ullage' : 'Last refill',
          value: this.isApp(this.PRODUCT_TYPE_FUEL)
            ? formatFillVolumeDisplay(this.currentEntity)
            : formatLastRefillVolumeDisplay(this.currentEntity),
          subtitleValue: formatLastRefillTileDisplay(this.currentEntity),
          icon: this.isApp(this.PRODUCT_TYPE_FUEL) ? 'down' : 'up',
          isLeftTile: false,
          disabledMessage: 'No data available to display'
        },
        disabled:
          !isLive(this.currentEntity) ||
          checkFuelFeedMetricUnavailable(this.currentEntity, [
            this.isApp(this.PRODUCT_TYPE_FUEL)
              ? 'fillVolume'
              : 'lastDeliveryVolume'
          ])
      },
      {
        tileElements: {
          title: 'Average daily use',
          value: this.isApp(this.PRODUCT_TYPE_FUEL)
            ? formatDailyUseDisplay(this.currentEntity)
            : formatFeedDailyUseDisplay(this.currentEntity),
          subtitleValue: formatDailyUseDateDisplay(this.currentEntity),
          icon: 'data',
          isLeftTile: true,
          disabledMessage: 'No data available to display'
        },
        disabled:
          !isLive(this.currentEntity) ||
          checkFuelFeedMetricUnavailable(this.currentEntity, ['dailyUse'])
      },
      {
        tileElements: {
          title: 'Estimated days until empty',
          value: formatDaysTillEmptyDisplay(this.currentEntity),
          subtitleValue: formatDaysTillEmptyDateDisplay(this.currentEntity),
          icon: 'date',
          isLeftTile: false,
          disabledMessage: 'No data available to display'
        },
        disabled:
          !isLive(this.currentEntity) ||
          checkFuelFeedMetricUnavailable(this.currentEntity, ['daysTillEmpty']),
        classAlterations: { title: { daysTillEmpP: this.width > 1000 } }
      }
    ];
  }

  get waterTileDetails() {
    if (!this.currentEntity) return [];
    return [
      {
        tileElements: {
          title: 'Usage today',
          value: formatUsageTodayDisplay(this.currentEntity),
          subtitleValue: '',
          icon: 'usage',
          isLeftTile: true,
          disabledMessage: 'No data available to display'
        },
        disabled:
          !isLive(this.currentEntity) ||
          checkWaterMetricUnavailable(this.currentEntity, 'usageToday')
      },
      {
        tileElements: {
          title: 'Usage yesterday',
          value: formatUsageYesterdayDisplay(this.currentEntity),
          subtitleValue: '',
          icon: 'usage-yesterday',
          isLeftTile: false,
          disabledMessage: 'No data available to display'
        },
        disabled:
          !isLive(this.currentEntity) ||
          checkWaterMetricUnavailable(this.currentEntity, 'usageYesterday')
      },
      {
        tileElements: {
          title: 'Current usage',
          value: formatCurrentUsageDisplay(this.currentEntity),
          subtitleValue: '',
          icon: 'water',
          isLeftTile: null,
          disabledMessage: 'No data available to display'
        },
        disabled:
          !isLive(this.currentEntity) ||
          checkWaterMetricUnavailable(this.currentEntity, 'currentUsage')
      }
    ];
  }

  private relativeDay(timestamp: number) {
    return this.$options?.filters?.relativeDay(timestamp);
  }

  public getMetricTileDetails(row: number) {
    if (this.currentEntity) {
      if (this.isApp(this.PRODUCT_TYPE_MILK)) {
        return this.milkTileDetails.slice(row * 2 - 2, row * 2);
      } else if (this.isApp(this.PRODUCT_TYPE_WATER)) {
        return this.waterTileDetails.slice(row * 2 - 2, row * 2);
      } else {
        return this.feedFuelTileDetails.slice(row * 2 - 2, row * 2);
      }
    }
  }
}
