
import FormDropdownInput from '@/components/common/FormDropdownInput.vue';
import FormTextInput from '@/components/common/FormTextInput.vue';
import GoogleMap from '@/components/GoogleMap.vue';
import PartnerCustomNames from '@/components/items/PartnerCustomNames.vue';
import { Product } from '@/store/models/Product';
import { Constants } from '@/utils/Constants';
import { getAppMapTag } from '@/utils/GenericUtils';
import { getObjectItem } from '@/utils/LocalStorageUtils';
import { mixins } from 'vue-class-component';
import { Component, Prop, Ref } from 'vue-property-decorator';
import CustomFieldDataListCard from '../cards/CustomFieldDataListCard.vue';
import DetailsCard from '../cards/DetailsCard.vue';
import SidebarCard from '../common/SidebarCard.vue';
import SiteGraphsGroup from '../groups/SiteGraphsGroup.vue';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';

@Component({
  components: {
    FormTextInput,
    FormDropdownInput,
    CustomFieldDataListCard,
    PartnerCustomNames,
    GoogleMap,
    SiteGraphsGroup,
    DetailsCard,
    SidebarCard
  }
})
export default class SiteSideContent extends mixins(AppName, ConstantsMixin) {
  @Ref('sidebarMap') readonly googleMap!: GoogleMap;
  @Prop() selectedEntityState!: Product;
  @Prop() tankIndex!: number;
  @Prop() width!: number;

  mounted() {
    this.addMarkerForProduct(this.selectedEntityState);
  }

  get numPendingEntities() {
    let numberPendingEntities = 0;
    this.selectedEntityState?.entities?.forEach(entity => {
      if (entity.status != Constants.ENTITY_STATUS_LIVE) {
        numberPendingEntities += 1;
      }
    });
    return numberPendingEntities;
  }

  public updatedLabels(name: string, id: string) {
    this.$emit('updatedLabels', name, id);
  }

  private addMarkerForProduct(product: Product): void {
    if (this.googleMap) {
      const mapSettings = getObjectItem(`${this.getAppName(false)}MapSettings`);
      let mapTagValue = '';
      if (mapSettings) {
        mapTagValue = mapSettings.mapTag;
      }
      this.googleMap.addMarker({
        lat: parseFloat(product.latitude),
        lon: parseFloat(product.longitude),
        tag:
          this.getAppName(false) == Constants.PRODUCT_TYPE_MILK
            ? +product.supplierNumber
            : product.productId,
        markerVal: getAppMapTag(mapTagValue, product),
        supplyNum: product.supplierNumber,
        isSelected: true,
        mapTagValue: mapTagValue
      });
    }
  }
}
