import { render, staticRenderFns } from "./SideContent.vue?vue&type=template&id=636ee5a4&"
import script from "./SideContent.vue?vue&type=script&lang=ts&"
export * from "./SideContent.vue?vue&type=script&lang=ts&"
import style0 from "./SideContent.vue?vue&type=style&index=0&id=636ee5a4&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports