
import FeedBalance from '@/services/api/models/FeedBalance';
import TankTimeseries from '@/services/api/models/TankTimeseries';
import Withdrawal from '@/services/api/models/Withdrawal';
import { trackEvent } from '@/services/Mixpanel';
import { FeedMetrics, FuelMetricsTank, Product } from '@/store/models/Product';
import ProductStore from '@/store/modules/ProductStore';
import { Constants } from '@/utils/Constants';
import {
  getEndOfDay,
  getStartOfDay,
  isWorkingDay,
  workingDaysToFrom
} from '@/utils/DateUtils';
import { toTitleCase } from '@/utils/FormattingUtils';
import '@/utils/RoundedBar.ts';
import Chart, { ChartConfiguration, ChartData, ChartOptions } from 'chart.js';
import dayjs, { Dayjs } from 'dayjs';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';
import fuelPlaceholderData from '@/services/api/fuelPlaceholderData.json';
import { isApp } from '@/utils/AppName';
import { FuelCustomSettingsData } from '@/store/models/CustomSettingsData';
import { formatFuelFeedValue, roundFuelFeedValue } from '@/utils/GraphUtils';
import FeedLevel from '@/services/api/models/FeedLevel';

@Component
export default class BurndownActivityGraph extends mixins(
  AppName,
  ConstantsMixin
) {
  @Prop({ default: ['#B4E2DE', '#33B1A6'] }) readonly colors!: Array<string>;
  @Prop() day!: Dayjs;
  @Prop() unit!: string;
  @Prop() selectedEntityId!: number;
  @Prop() selecedEntityCap!: number;
  @Prop() selectedEntity!: Product;
  @Prop() location!: string;
  @Prop() disabled!: boolean;

  public endDay = this.day;
  public loading = true;
  public noData = false;
  public oldSize = window.innerWidth;
  private chart!: Chart;
  private dataSet!: Withdrawal[];
  private refills!: Withdrawal[];
  private withdrawals!: Withdrawal[];
  private refillWithdrawalLabel!: {
    balance: number;
    value: number;
    label: string;
    timestamp: number;
  }[];
  private projectedLowUsage!: { x: number; y: number | null }[];
  private projectedNormalUsage!: { x: number; y: number | null }[];
  private projectedHighUsage!: { x: number; y: number | null }[];
  private usage!: { x: number; y: number | null }[];
  public view = 'daily';
  public timePeriod = {
    from: this.day.subtract(5, 'day').format(),
    to: this.day.format()
  };
  public disable = true;
  public value = null;
  public dataDayProportions = {
    sidebarPrevious: 5,
    sidebarFuture: 3,
    modalPrevious: 20,
    modalFuture: 10
  };
  public longDaysTillEmp = false;
  public noDaysTillEmp = false;

  @Prop({
    default: () => {
      return Chart.defaults.bar;
    }
  })
  readonly options: object | undefined;
  public get graphOptions() {
    return {
      cornerRadius: this.location == 'modal' ? 15 : 5,
      legend: {
        display: false
      },
      tooltips: {
        enabled: false,
        custom: this.processTooltipModel,
        intersect: false,
        mode: 'index'
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false
            },
            ticks: {
              autoSkip: true,
              source: 'labels',
              callback(label, index, labels) {
                return `${label}`;
              },
              major: true,
              display: !this.disabled
            },
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'D MMM'
              }
            }
          }
        ],
        yAxes: [
          {
            gridLines: {
              display: false
            },
            ticks: {
              display: !this.disabled,
              beginAtZero: true,
              maxTicksLimit: this.location == 'modal' ? 15 : 6
            }
          }
        ]
      },
      plugins: {
        datalabels: {
          display: false
        }
      }
    } as ChartOptions;
  }

  mounted() {
    this.resetTimePeriod();
    this.updateData(true);
    this.$root.$on('customFieldsUpdated', () => {
      if (!this.disabled && !this.noData && !this.longDaysTillEmp) {
        setTimeout(() => {
          this.reformatUpdate();
        }, 1000);
      }
    });
  }

  @Watch('selectedEntityId')
  selectedUpdated() {
    this.longDaysTillEmp = false;
    this.noDaysTillEmp = false;
    this.resetTimePeriod();
    this.updateData(false);
  }

  get timePeriodFormatted() {
    return `${dayjs(this.timePeriod.from).format('ddd D MMM YY')} - ${dayjs(
      this.timePeriod.to
    ).format('ddd D MMM YY')}`;
  }

  public initChart() {
    const data = this.getData();
    this.createChart(data);
    this.loading = false;
  }

  public update(timePeriod: { from: string; to: string }, view: string) {
    this.view = view;
    this.timePeriod = timePeriod;
    this.updateData(
      false,
      dayjs(timePeriod.from)
        .unix()
        .toString(),
      dayjs(timePeriod.to)
        .unix()
        .toString()
    );
  }

  public showBurndownGraphModal() {
    if (this.isApp(this.PRODUCT_TYPE_FEED)) {
      this.$root.$emit('bv::show::modal', 'feedLevelGraphModal', '#btnShow');
    } else {
      this.$root.$emit('bv::show::modal', 'fuelBurndownGraphModal', '#btnShow');
    }
  }

  public createChart(chartData: object) {
    const canvas: HTMLCanvasElement = this.$refs[
      `fuelLine-${this.location}`
    ] as HTMLCanvasElement;
    if (canvas) {
      const options: ChartConfiguration = {
        type: 'line',
        data: chartData,
        options: this.graphOptions
      };
      this.chart = new Chart(canvas, options);
    }
  }

  public getData(): ChartData | any {
    this.getLabels();
    if (dayjs(this.timePeriod.to).isAfter(this.day)) {
      return {
        datasets: [
          {
            label: 'Projected Normal',
            data: this.projectedNormalUsage,
            borderColor: this.colors[1],
            borderDash: [2, 3],
            borderWidth: 2,
            backgroundColor: '#fff',
            tension: 0
          },
          {
            label: 'Projected Low',
            data: this.projectedLowUsage,
            borderColor: this.disabled ? '#ADADB6' : '#190897',
            borderDash: [2, 3],
            borderWidth: 2,
            backgroundColor: '#fff',
            tension: 0
          },
          {
            label: 'Projected High',
            data: this.projectedHighUsage,
            borderColor: '#6D6D6D',
            borderDash: [2, 3],
            borderWidth: 2,
            backgroundColor: '#fff',
            tension: 0
          },
          {
            label: 'Usage',
            data: this.usage,
            borderColor: this.colors[1],
            backgroundColor: this.colors[0],
            tension: 0
          }
        ],
        labels: this.getLabels()
      };
    } else {
      return {
        datasets: [
          {
            label: 'Usage',
            data: this.usage,
            borderColor: this.colors[1],
            backgroundColor: this.colors[0],
            tension: 0,
            pointBackgroundColour: '#ff0000'
          }
        ],
        labels: this.getLabels()
      };
    }
  }

  public getLabels() {
    const labels = new Set();

    if (dayjs(this.timePeriod.to).isAfter(this.day)) {
      this.projectedNormalUsage.forEach(usage => {
        labels.add(dayjs(usage.x).format('D MMM YYYY'));
      });

      return Array.from(labels)
        .map((time: any) =>
          dayjs(time)
            .hour(23)
            .minute(59)
            .second(59)
            .valueOf()
        )
        .sort((a: any, b: any) => {
          return a > b ? 1 : -1;
        });
    } else {
      this.usage.forEach(usage => {
        labels.add(dayjs(usage.x).format('D MMM YYYY'));
      });
      return Array.from(labels)
        .map((time: any) =>
          dayjs(time)
            .hour(23)
            .minute(59)
            .second(59)
            .valueOf()
        )
        .sort((a: any, b: any) => {
          return a > b ? 1 : -1;
        });
    }
  }

  public startFromDate() {
    return dayjs(this.day).subtract(
      this.location == 'modal'
        ? this.dataDayProportions.modalPrevious
        : this.dataDayProportions.sidebarPrevious,
      'day'
    );
  }

  public resetTimePeriod() {
    if (
      this.location == 'modal' &&
      this.dataDayProportions.modalPrevious == 2
    ) {
      //detailed view
      this.timePeriod.from = getStartOfDay(
        this.day.subtract(
          this.location == 'modal'
            ? 1
            : this.dataDayProportions.sidebarPrevious,
          'day'
        )
      )
        .subtract(1, 'minute')
        .format();
      this.timePeriod.to = this.day
        .add(
          this.location == 'modal'
            ? this.dataDayProportions.modalFuture
            : this.dataDayProportions.sidebarFuture,
          'day'
        )
        .format();
    } else {
      this.timePeriod.from = this.day
        .subtract(
          this.location == 'modal'
            ? this.dataDayProportions.modalPrevious
            : this.dataDayProportions.sidebarPrevious,
          'day'
        )
        .format();
      this.timePeriod.to = this.day
        .add(
          this.location == 'modal'
            ? this.dataDayProportions.modalFuture
            : this.dataDayProportions.sidebarFuture,
          'day'
        )
        .format();
    }
  }

  public updateData(
    initialise: boolean,
    from = dayjs(this.timePeriod.from)
      .unix()
      .toString(),
    to = dayjs(this.timePeriod.to)
      .unix()
      .toString()
  ) {
    this.loading = true;
    if (!dayjs(this.timePeriod.from).isAfter(this.day)) {
      ProductStore.fetchActivityGraphData({
        appName: this.getAppName(false),
        entityId: this.selectedEntityId,
        from: +from,
        to: +to,
        disabled: this.disabled,
        source: `${this.getAppName(true)} Burndown Activity Graph`
      }).then(data => {
        if (
          ((this.isApp(this.PRODUCT_TYPE_FEED) &&
            (data as { balance?: FeedBalance[]; level?: FeedLevel[] }).level
              ?.length == 0) ||
            (this.isApp(this.PRODUCT_TYPE_FUEL) &&
              (data as TankTimeseries)?.daily?.length == 0)) &&
          !dayjs(this.timePeriod.from).isAfter(this.day)
        ) {
          this.noData = true;
        } else {
          this.noData = false;
          try {
            if (this.getAppName(false) == Constants.PRODUCT_TYPE_FEED) {
              this.dataSet =
                (data as {
                  balance?: FeedBalance[];
                  level?: FeedLevel[];
                }).level?.map(value => {
                  return {
                    date: dayjs(value.date).unix(),
                    timestamp: dayjs(value.date).unix(),
                    value: formatFuelFeedValue(
                      this.unit == 't'
                        ? value.weight ?? value.volumeWeight ?? 0
                        : value.volume,
                      this.unit
                    )
                  };
                }) ?? [];
              this.refills = [];
              this.withdrawals = [];
            } else {
              this.dataSet = (data as TankTimeseries).daily ?? [];
              this.refills = (data as TankTimeseries).refill ?? [];
              this.withdrawals = (data as TankTimeseries).withdrawal ?? [];
            }
            if (this.dataDayProportions.modalPrevious == 2) {
              //detailview
              this.getDetailData();
            } else {
              this.getDataWithRefills();
            }
            this.formatGraphData();
            if (initialise || !this.chart) {
              this.initChart();
            } else {
              const data: ChartData = this.getData();
              this.chart.data = data;
              this.chart.options = this.graphOptions;
              this.chart.update();
            }
          } catch (e) {
            console.error('Internal error', e);
            this.$bvToast.toast('Graph not updated' + e, {
              title: 'Error',
              toaster: 'b-toaster-bottom-center',
              solid: true,
              append: false
            });
          }
        }
        this.loading = false;
      });
    } else {
      this.noData = false;
      try {
        this.formatGraphData();
        if (initialise || !this.chart) {
          this.initChart();
        } else {
          const data: ChartData = this.getData();
          this.chart.data = data;
          this.chart.options = this.graphOptions;
          this.chart.update();
        }
      } catch (e) {
        console.error('Internal error', e);
        this.$bvToast.toast('Graph not updated' + e, {
          title: 'Error',
          toaster: 'b-toaster-bottom-center',
          solid: true,
          append: false
        });
      }
      this.loading = false;
    }
  }

  public reformatUpdate() {
    try {
      this.formatGraphData();
      const data: ChartData = this.getData();
      this.chart.data = data;
      this.chart.options = this.graphOptions;
      this.chart.update();
    } catch (e) {
      console.error('Internal error', e);
      this.$bvToast.toast('Graph not updated' + e, {
        title: 'Error',
        toaster: 'b-toaster-bottom-center',
        solid: true,
        append: false
      });
    }
  }

  public formatGraphData(): any {
    const volume = !this.disabled
      ? formatFuelFeedValue(
          (this.selectedEntity.metrics as FuelMetricsTank)?.volume,
          this.unit
        )
      : fuelPlaceholderData.entity.metrics.volume;
    const projectedLowTotals: { x: number; y: number | null }[] = []; //low use x1.5
    const projectedNormalTotals: { x: number; y: number | null }[] = []; //normal use
    const projectedHighTotals: { x: number; y: number | null }[] = []; //high use x0.67
    this.noDaysTillEmp =
      !this.disabled &&
      (this.selectedEntity.metrics as FuelMetricsTank)?.daysTillEmpty == null;
    this.longDaysTillEmp =
      !this.disabled &&
      (this.selectedEntity.metrics as FuelMetricsTank)?.daysTillEmpty > 365;
    let products: { x: number; y: number | null }[] = [];
    if (!dayjs(this.timePeriod.from).isAfter(this.day)) {
      products = this.dataSet.map((entry: Withdrawal) => {
        const day = dayjs.unix(entry.timestamp).valueOf();
        return { x: day, y: entry.value };
      });

      products.forEach(value => {
        projectedLowTotals.push({ x: value.x, y: null });
        projectedNormalTotals.push({ x: value.x, y: null });
        projectedHighTotals.push({ x: value.x, y: null });
      });
    }
    if (dayjs(this.timePeriod.to).isAfter(this.day)) {
      let projectedNumber =
        (this.location == 'modal'
          ? this.dataDayProportions.modalFuture
          : this.dataDayProportions.sidebarFuture) + 1;
      let startDayIndex = 1;
      if (dayjs(this.timePeriod.from).isAfter(this.day)) {
        startDayIndex = dayjs(this.timePeriod.from).diff(this.day, 'day') + 1;
        projectedNumber =
          dayjs(this.timePeriod.to).diff(dayjs(this.timePeriod.from), 'day') +
          startDayIndex +
          1;
      }
      const usageDayPattern = (this.selectedEntity
        .entityCustomSettingsData as FuelCustomSettingsData)?.usageDayPattern;
      let daysTillEmp;
      if (!this.disabled) {
        daysTillEmp = isApp(this.PRODUCT_TYPE_FUEL)
          ? (this.selectedEntity.metrics as FuelMetricsTank)
              ?.workingDaysTillEmpty
          : (this.selectedEntity.metrics as FeedMetrics)?.daysTillEmpty;
      } else {
        daysTillEmp = fuelPlaceholderData.entity.metrics.daysTillEmpty;
      }
      const daysTillEmpLow = daysTillEmp * 1.5;
      const daysTillEmpHigh = daysTillEmp * 0.67;

      let workingDayIndex = startDayIndex;

      let prevVolume = volume;
      let prevVolumeLow = volume;
      let prevVolumeHigh = volume;
      if (!dayjs(this.timePeriod.from).isAfter(this.day)) {
        products.push({
          x: this.day
            .hour(23)
            .minute(59)
            .second(59)
            .valueOf(),
          y: volume
        });
        projectedLowTotals.push({
          x: this.day
            .hour(23)
            .minute(59)
            .second(59)
            .valueOf(),
          y: volume
        });
        projectedNormalTotals.push({
          x: this.day
            .hour(23)
            .minute(59)
            .second(59)
            .valueOf(),
          y: volume
        });
        projectedHighTotals.push({
          x: this.day
            .hour(23)
            .minute(59)
            .second(59)
            .valueOf(),
          y: volume
        });
      } else {
        workingDayIndex =
          workingDaysToFrom(
            usageDayPattern ?? 7,
            dayjs(),
            getEndOfDay(dayjs(this.timePeriod.from))
          ) ?? 1;
        prevVolume =
          ((daysTillEmp - workingDayIndex) / daysTillEmp) * volume || 0;
        prevVolumeLow =
          ((daysTillEmpLow - workingDayIndex) / daysTillEmpLow) * volume || 0;
        prevVolumeHigh =
          ((daysTillEmpHigh - workingDayIndex) / daysTillEmpHigh) * volume || 0;
      }
      for (let i = startDayIndex; i < projectedNumber; i++) {
        const currentIndexDay = this.day
          .hour(23)
          .minute(59)
          .second(59)
          .add(i, 'day');
        const isFlatLineDay =
          isApp(this.PRODUCT_TYPE_FUEL) &&
          !isWorkingDay(usageDayPattern ?? 7, currentIndexDay);
        if (!isFlatLineDay && i != startDayIndex) {
          workingDayIndex++;
        }
        //Normal
        if (this.longDaysTillEmp || this.noDaysTillEmp) {
          projectedNormalTotals.push({
            x: currentIndexDay.valueOf(),
            y: volume
          });
        } else if (isFlatLineDay) {
          projectedNormalTotals.push({
            x: currentIndexDay.valueOf(),
            y: prevVolume
          });
        } else if (
          daysTillEmp > 0 &&
          ((daysTillEmp - workingDayIndex) / daysTillEmp) * volume > 0
        ) {
          prevVolume = ((daysTillEmp - workingDayIndex) / daysTillEmp) * volume;
          projectedNormalTotals.push({
            x: currentIndexDay.valueOf(),
            y: prevVolume
          });
        } else {
          prevVolume = 0;
          projectedNormalTotals.push({
            x: currentIndexDay.valueOf(),
            y: 0
          });
        }

        //Low
        if (this.longDaysTillEmp || this.noDaysTillEmp) {
          projectedLowTotals.push({
            x: currentIndexDay.valueOf(),
            y: volume
          });
        } else if (isFlatLineDay) {
          projectedLowTotals.push({
            x: currentIndexDay.valueOf(),
            y: prevVolumeLow
          });
        } else if (
          daysTillEmpLow > 0 &&
          ((daysTillEmpLow - workingDayIndex) / daysTillEmpLow) * volume > 0
        ) {
          prevVolumeLow =
            ((daysTillEmpLow - workingDayIndex) / daysTillEmpLow) * volume;
          projectedLowTotals.push({
            x: currentIndexDay.valueOf(),
            y: prevVolumeLow
          });
        } else {
          prevVolumeLow = 0;
          projectedLowTotals.push({
            x: currentIndexDay.valueOf(),
            y: 0
          });
        }

        //High
        if (this.longDaysTillEmp || this.noDaysTillEmp) {
          projectedHighTotals.push({
            x: currentIndexDay.valueOf(),
            y: volume
          });
        } else if (isFlatLineDay) {
          projectedHighTotals.push({
            x: currentIndexDay.valueOf(),
            y: prevVolumeHigh
          });
        } else if (
          daysTillEmpHigh > 0 &&
          ((daysTillEmpHigh - workingDayIndex) / daysTillEmpHigh) * volume > 0
        ) {
          prevVolumeHigh =
            ((daysTillEmpHigh - workingDayIndex) / daysTillEmpHigh) * volume;
          projectedHighTotals.push({
            x: currentIndexDay.valueOf(),
            y: prevVolumeHigh
          });
        } else {
          prevVolumeHigh = 0;
          projectedHighTotals.push({
            x: currentIndexDay.valueOf(),
            y: 0
          });
        }
      }

      this.projectedLowUsage = projectedLowTotals;
      this.projectedNormalUsage = projectedNormalTotals;
      this.projectedHighUsage = projectedHighTotals;
    }
    this.usage = products;
  }

  public getDataWithRefills() {
    const dataWithRefills: Withdrawal[] = [];
    const dataWithLabels = [
      ...this.dataSet.map(item => {
        return { ...item, label: 'daily' };
      }),
      ...this.refills.map(item => {
        return { ...item, label: 'refill' };
      })
    ].sort((a: Withdrawal, b: Withdrawal) => {
      return a.timestamp > b.timestamp ? 1 : -1;
    });
    const dataWithRefillLabels: {
      balance: number;
      value: number;
      label: string;
      timestamp: number;
    }[] = [];

    let previousValue = 0;
    let hasBeenDailyValue = false;
    dataWithLabels.forEach(item => {
      if (item.label == 'daily') {
        hasBeenDailyValue = true;
        previousValue = item.value;
        dataWithRefills.push({
          timestamp: item.timestamp,
          date: item.timestamp,
          value: item.value
        });
        dataWithRefillLabels.push({
          timestamp: item.timestamp,
          balance: item.value,
          value: item.value,
          label: item.label
        });
      } else if (item.label == 'refill' && hasBeenDailyValue) {
        dataWithRefills.push({
          timestamp: item.startTime ?? item.timestamp - 1,
          date: item.timestamp,
          value: item.startVolume ?? previousValue
        });
        dataWithRefills.push({
          timestamp: item.timestamp,
          date: item.timestamp,
          value: item.endVolume ?? previousValue + item.value
        });
        dataWithRefillLabels.push({
          timestamp: item.startTime ?? item.timestamp - 1,
          balance: item.endVolume ?? previousValue + item.value,
          value: item.value,
          label: item.label
        });
        dataWithRefillLabels.push({
          timestamp: item.timestamp,
          balance: item.endVolume ?? previousValue + item.value,
          value: item.value,
          label: item.label
        });

        previousValue = previousValue + item.value;
      }
    });

    this.dataSet = dataWithRefills.sort((a: Withdrawal, b: Withdrawal) => {
      return a.timestamp > b.timestamp ? 1 : -1;
    });
    this.refillWithdrawalLabel = dataWithRefillLabels.sort((a: any, b: any) => {
      return a.timestamp > b.timestamp ? 1 : -1;
    });
  }

  public getDetailData() {
    //get the detailed data
    const dataWithdrawalRefills: Withdrawal[] = [];
    const dataWithdrawalRefillsLabels: {
      balance: number;
      value: number;
      label: string;
      timestamp: number;
    }[] = [];

    const dataWithLabels = [
      ...this.withdrawals.map(item => {
        return { ...item, label: 'withdrawal' };
      }),
      ...this.refills.map(item => {
        return { ...item, label: 'refill' };
      }),
      ...this.dataSet.map(item => {
        return { ...item, label: 'daily' };
      })
    ].sort((a: Withdrawal, b: Withdrawal) => {
      return a.timestamp > b.timestamp ? 1 : -1;
    });

    let previousValue = 0;
    dataWithLabels.forEach(item => {
      if (item.label == 'withdrawal') {
        dataWithdrawalRefills.push({
          timestamp: item.timestamp - 1,
          date: item.timestamp,
          value: previousValue
        });
        dataWithdrawalRefills.push({
          timestamp: item.timestamp,
          date: item.timestamp,
          value: previousValue - item.value
        });
        dataWithdrawalRefillsLabels.push({
          timestamp: item.timestamp - 1,
          balance: previousValue - item.value,
          value: item.value,
          label: item.label
        });
        dataWithdrawalRefillsLabels.push({
          timestamp: item.timestamp,
          balance: previousValue - item.value,
          value: item.value,
          label: item.label
        });

        previousValue = previousValue - item.value;
      } else if (item.label == 'refill') {
        dataWithdrawalRefills.push({
          timestamp: item.timestamp - 1,
          date: item.timestamp,
          value: previousValue
        });
        dataWithdrawalRefills.push({
          timestamp: item.timestamp,
          date: item.timestamp,
          value: previousValue + item.value
        });
        dataWithdrawalRefillsLabels.push({
          timestamp: item.timestamp - 1,
          balance: previousValue + item.value,
          value: item.value,
          label: item.label
        });
        dataWithdrawalRefillsLabels.push({
          timestamp: item.timestamp,
          balance: previousValue + item.value,
          value: item.value,
          label: item.label
        });

        previousValue = previousValue + item.value;
      } else {
        previousValue = item.value;
        dataWithdrawalRefills.push({
          timestamp: item.timestamp,
          date: item.timestamp,
          value: item.value
        });
        dataWithdrawalRefillsLabels.push({
          timestamp: item.timestamp,
          balance: item.value,
          value: item.value,
          label: item.label
        });
      }
    });
    if (dayjs(this.timePeriod.to).isAfter(this.day.subtract(1, 'day'))) {
      dataWithdrawalRefills.push({
        timestamp: this.day.unix(),
        date: this.day.unix(),
        value: (this.selectedEntity.metrics as FuelMetricsTank)?.volume
      });
      dataWithdrawalRefillsLabels.push({
        timestamp: this.day.unix(),
        balance: (this.selectedEntity.metrics as FuelMetricsTank)?.volume,
        value: (this.selectedEntity.metrics as FuelMetricsTank)?.volume,
        label: 'current'
      });
    }

    this.dataSet = dataWithdrawalRefills.sort(
      (a: Withdrawal, b: Withdrawal) => {
        return a.timestamp > b.timestamp ? 1 : -1;
      }
    );
    this.refillWithdrawalLabel = dataWithdrawalRefillsLabels.sort(
      (a: any, b: any) => {
        return a.timestamp > b.timestamp ? 1 : -1;
      }
    );
  }

  public formatTimePeriod(): string {
    return (
      dayjs(this.timePeriod.from).format('D MMM - ') +
      dayjs(this.timePeriod.to).format('D MMM')
    );
  }

  public changeTimePeriod(direction: string) {
    this.loading = true;
    if (direction == 'left') {
      if (
        this.location == 'modal' &&
        this.dataDayProportions.modalPrevious == 2
      ) {
        this.timePeriod.to = getEndOfDay(dayjs(this.timePeriod.from)).format();
        this.timePeriod.from = dayjs(this.timePeriod.from)
          .subtract(
            this.location == 'modal'
              ? this.dataDayProportions.modalPrevious +
                  this.dataDayProportions.modalFuture
              : 8,
            'day'
          )
          .format();
      } else {
        this.timePeriod.to = this.timePeriod.from;
        this.timePeriod.from = dayjs(this.timePeriod.from)
          .subtract(
            this.location == 'modal'
              ? this.dataDayProportions.modalPrevious +
                  this.dataDayProportions.modalFuture
              : 8,
            'day'
          )
          .format();
      }

      this.updateData(false);
    } else {
      if (
        this.location == 'modal' &&
        this.dataDayProportions.modalPrevious == 2
      ) {
        this.timePeriod.from = dayjs(this.timePeriod.to).format();
        const to = dayjs(this.timePeriod.to).add(
          this.location == 'modal'
            ? this.dataDayProportions.modalPrevious +
                this.dataDayProportions.modalFuture
            : 8,
          'day'
        );
        if (to.isAfter(this.day.subtract(1, 'day'))) {
          this.timePeriod.to = this.day.format();
        } else {
          this.timePeriod.to = to.format();
        }
      } else {
        this.timePeriod.from = dayjs(this.timePeriod.to).format();
        this.timePeriod.to = dayjs(this.timePeriod.to)
          .add(
            this.location == 'modal'
              ? this.dataDayProportions.modalPrevious +
                  this.dataDayProportions.modalFuture
              : 8,
            'day'
          )
          .format();
      }
      this.updateData(false);
    }
  }

  public processTooltipModel(model: any) {
    if (!model.body) {
      return;
    }

    const tooltip = document.getElementById(`tooltip-${this.location}`);
    if (tooltip) {
      const pos = model.caretX + (this.location == 'sidebar' ? 5 : 21);
      if (pos < 90 && this.location == 'sidebar') {
        tooltip.style.left = '90px';
      } else if (pos > 260 && this.location == 'sidebar') {
        tooltip.style.left = '260px';
      } else {
        tooltip.style.left = pos + 'px';
      }
      tooltip.style.display = 'block';
      const line = document.getElementById(`tooltip-line-${this.location}`);
      if (line) {
        line.style.left =
          model.caretX + (this.location == 'sidebar' ? 15 : 31) + 'px';
      }
      const label = tooltip.querySelector('.tooltip-label');
      if (label) {
        if (
          dayjs(model.dataPoints[0].label).format('hh:mm:ss a') == '11:59:59 pm'
        ) {
          label.textContent = dayjs(model.dataPoints[0].label).format('D MMM');
        } else {
          label.textContent = dayjs(model.dataPoints[0].label).format(
            'h:mma D MMM'
          );
        }
      }
      if (
        (this.location == 'modal' &&
          this.dataDayProportions.modalPrevious == 2 &&
          this.refillWithdrawalLabel[model.dataPoints[0].index].label !=
            'daily' &&
          this.refillWithdrawalLabel[model.dataPoints[0].index].label !=
            'current') ||
        (this.refillWithdrawalLabel &&
          this.refillWithdrawalLabel.length > 0 &&
          this.refillWithdrawalLabel.length > model.dataPoints[0].index &&
          this.refillWithdrawalLabel[model.dataPoints[0].index].label ==
            'refill')
      ) {
        //Detail view tooltip
        const projected = document.getElementById(
          `tooltip-projected-${this.location}`
        );
        if (projected) {
          projected.style.display = 'none';
        }
        const usage = document.getElementById(`tooltip-usage-${this.location}`);
        if (usage) {
          usage.style.display = 'none';
        }
        const detail = document.getElementById(
          `tooltip-detail-${this.location}`
        );
        if (detail) {
          detail.style.display = 'block';
        }
        const balance = tooltip.querySelector('.tooltip-value-balance .value');
        if (balance) {
          balance.textContent = roundFuelFeedValue(
            this.refillWithdrawalLabel[model.dataPoints[0].index].balance,
            this.unit
          );
        }
        const percent = tooltip.querySelector(
          '.tooltip-value-balance .percent'
        );
        if (percent) {
          percent.textContent = Math.round(
            (+this.refillWithdrawalLabel[model.dataPoints[0].index].balance /
              formatFuelFeedValue(
                (this.selectedEntity.metrics as FuelMetricsTank).nominalVolume,
                this.unit
              )) *
              100
          ).toString();
        }
        const detailLabel = tooltip.querySelector(
          '.tooltip-value-detail .label'
        );
        if (detailLabel) {
          detailLabel.textContent =
            toTitleCase(
              this.refillWithdrawalLabel[model.dataPoints[0].index].label
            ).toString() + ' Amount';
        }
        const detailEndLabel = tooltip.querySelector(
          '.tooltip-value-detail .endLabel'
        );
        if (detailEndLabel) {
          detailEndLabel.textContent =
            'End of ' +
            toTitleCase(
              this.refillWithdrawalLabel[model.dataPoints[0].index].label
            ).toString() +
            ' Balance';
        }
        const detailValue = tooltip.querySelector(
          '.tooltip-value-detail .value'
        );
        if (detailValue) {
          detailValue.textContent = this.refillWithdrawalLabel[
            model.dataPoints[0].index
          ].value.toString();
        }
      } else if (model.body.length == 1 || model.body.length == 4) {
        //Daily value tooltip
        tooltip.style.top =
          model.caretY +
          (this.location == 'sidebar' ||
          this.longDaysTillEmp ||
          this.noDaysTillEmp
            ? 19
            : -31) +
          'px';
        const projected = document.getElementById(
          `tooltip-projected-${this.location}`
        );
        if (projected) {
          projected.style.display = 'none';
        }
        const detail = document.getElementById(
          `tooltip-detail-${this.location}`
        );
        if (detail) {
          detail.style.display = 'none';
        }
        const usage = document.getElementById(`tooltip-usage-${this.location}`);
        if (usage) {
          usage.style.display = 'block';
        }
        const value = tooltip.querySelector('.tooltip-value-usage .value');
        if (value) {
          value.textContent = roundFuelFeedValue(
            +model.dataPoints[0].value,
            this.unit
          );
        }
        const percent = tooltip.querySelector('.tooltip-value-usage .percent');
        if (percent) {
          percent.textContent = Math.round(
            (+model.dataPoints[0].value /
              formatFuelFeedValue(
                (this.selectedEntity.metrics as FuelMetricsTank).nominalVolume,
                this.unit
              )) *
              100
          ).toString();
        }
        if (
          model.body.length == 4 ||
          (this.refillWithdrawalLabel &&
            this.refillWithdrawalLabel.length != 0 &&
            this.refillWithdrawalLabel[model.dataPoints[0].index].label ==
              'current')
        ) {
          //Current tank balance tooltip
          const label = tooltip.querySelector('.tooltip-value-usage .label');
          if (label) {
            label.textContent = 'Current Balance';
          }
        } else {
          const label = tooltip.querySelector('.tooltip-value-usage .label');
          if (label) {
            label.textContent = 'Level';
          }
        }
      } else {
        //Projected values tooltip
        tooltip.style.top =
          model.caretY +
          (this.location == 'sidebar' ||
          this.longDaysTillEmp ||
          this.noDaysTillEmp
            ? -70
            : -151) +
          'px';
        const usage = document.getElementById(`tooltip-usage-${this.location}`);
        if (usage) {
          usage.style.display = 'none';
        }
        const detail = document.getElementById(
          `tooltip-detail-${this.location}`
        );
        if (detail) {
          detail.style.display = 'none';
        }
        const projected = document.getElementById(
          `tooltip-projected-${this.location}`
        );
        if (projected) {
          projected.style.display = 'block';
        }

        const lowValue = tooltip.querySelector('.tooltip-value-low .value');
        if (lowValue) {
          lowValue.textContent = roundFuelFeedValue(
            +model.dataPoints[1].value,
            this.unit
          );
        }
        const value = tooltip.querySelector('.tooltip-value-normal .value');
        if (value) {
          value.textContent = roundFuelFeedValue(
            +model.dataPoints[0].value,
            this.unit
          );
        }
        const highValue = tooltip.querySelector('.tooltip-value-high .value');
        if (highValue) {
          highValue.textContent = roundFuelFeedValue(
            +model.dataPoints[2].value,
            this.unit
          );
        }
        const lowPercent = tooltip.querySelector('.tooltip-value-low .percent');
        if (lowPercent) {
          lowPercent.textContent = Math.round(
            (+model.dataPoints[1].value /
              formatFuelFeedValue(
                (this.selectedEntity.metrics as FuelMetricsTank).nominalVolume,
                this.unit
              )) *
              100
          ).toString();
        }
        const normalPercent = tooltip.querySelector(
          '.tooltip-value-normal .percent'
        );
        if (normalPercent) {
          normalPercent.textContent = Math.round(
            (+model.dataPoints[0].value /
              formatFuelFeedValue(
                (this.selectedEntity.metrics as FuelMetricsTank).nominalVolume,
                this.unit
              )) *
              100
          ).toString();
        }
        const highPercent = tooltip.querySelector(
          '.tooltip-value-high .percent'
        );
        if (highPercent) {
          highPercent.textContent = Math.round(
            (+model.dataPoints[2].value /
              formatFuelFeedValue(
                (this.selectedEntity.metrics as FuelMetricsTank).nominalVolume,
                this.unit
              )) *
              100
          ).toString();
        }
      }
    }
  }

  public changeView(previous: number, future: number) {
    if (previous == 2) {
      trackEvent(
        `User viewing  ${this.getAppName(false)} detail activity graph`
      );
    } else {
      trackEvent(
        `User viewing ${this.getAppName(false)} ${previous +
          future} day activity graph`
      );
    }
    this.dataDayProportions.modalPrevious = previous;
    this.dataDayProportions.modalFuture = future;
    this.resetTimePeriod();
    this.updateData(false);
  }

  public showTooltip() {
    const line = document.getElementById(`tooltip-line-${this.location}`);
    if (line) {
      line.style.display = 'block';
    }
    const tooltip = document.getElementById(`tooltip-${this.location}`);
    if (tooltip) {
      tooltip.style.display = 'block';
    }
  }

  public hideTooltip() {
    const line = document.getElementById(`tooltip-line-${this.location}`);
    if (line) {
      line.style.display = 'none';
    }
    const tooltip = document.getElementById(`tooltip-${this.location}`);
    if (tooltip) {
      tooltip.style.display = 'none';
    }
  }
}
