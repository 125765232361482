
import UsageChart from '@/components/graphs/UsageChart.vue';
import UsageGraphModal from '@/components/modal/UsageGraphModal.vue';
import { Product } from '@/store/models/Product';
import { getDayjsFromUnix, getToday } from '@/utils/DateUtils';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import SidebarCard from '../common/SidebarCard.vue';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';

@Component({
  components: { UsageChart, UsageGraphModal, SidebarCard },
  methods: { getDayjsFromUnix, getToday }
})
export default class UsageGraphCard extends mixins(AppName, ConstantsMixin) {
  @Prop() currentEntity!: Product;
  @Prop() title!: string;
  @Prop() isNotLive!: boolean;
}
