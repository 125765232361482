
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';

interface TileElements {
  title: string;
  value: string;
  unit: string;
  secondaryValue: string;
  subtitleValue: string;
  icon: string;
  isLeftTile: boolean;
  disabledMessage: string;
}

interface TileClassAlterations {
  card: string;
  cardText: string;
  title: string;
  value: string;
  secondaryValue: string;
  subtitleValue: string;
  borderVariant: string;
}

@Component
export default class MetricTile extends mixins(AppName) {
  @Prop() tileElements!: TileElements;
  @Prop({
    default: () => {
      return {
        card: '',
        cardText: '',
        title: '',
        value: '',
        secondaryValue: '',
        subtitleValue: '',
        borderVariant: ''
      };
    }
  })
  classAlterations!: TileClassAlterations;
  @Prop() width!: number;
  @Prop({ default: false }) disabled!: boolean;

  public getImage(icon: string) {
    if (this.disabled) {
      return require(`../../assets/disabled-${icon}.svg`);
    } else {
      return require(`../../assets/${this.getAppName(false)}-${icon}.svg`);
    }
  }
}
