
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Chart, {
  ChartConfiguration,
  ChartDataSets,
  ChartOptions
} from 'chart.js';
import dayjs from 'dayjs';
import { FetchProductsCombinedGraphData } from '@/services/api/ProductApi';
import MilkingFinish from '@/services/api/models/MilkingFinish';
import '@/utils/RoundedBar.ts';
import { Product } from '@/store/models/Product';
import { MilkProductTimeseriesReading } from '@/services/api/models/MilkProductTimeseries';
import groupBy from '@/utils/GroupBy';

@Component
export default class MilkingTimeBar extends Vue {
  @Prop({ default: [] }) readonly colors!: Array<string>;
  @Prop() day!: string;
  @Prop() selectedVatId!: number;
  @Prop() selectedProduct!: Product;
  @Prop() location!: string;
  @Prop() disabled!: boolean;

  public endDay = this.day;
  public offset = 0;
  public timePeriod = '';
  public disable = true;
  public loading = true;
  public noData = false;
  public oldSize = window.innerWidth;
  private chart!: Chart;
  private graphData!: MilkProductTimeseriesReading[];
  public am!: number[][];
  public pm!: number[][];

  @Prop({
    default: () => {
      return Chart.defaults.bar;
    }
  })
  readonly options: object | undefined;
  public graphOptions = {
    cornerRadius: this.location == 'modal' ? 10 : 2,
    legend: {
      display: true,
      position: 'top',
      labels: {
        padding: 20
      }
    },
    tooltips: {
      displayColors: false,
      enabled: !this.disabled,
      callbacks: {
        label: (tooltipItems: any) => {
          return this.getTime(tooltipItems);
        }
      }
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            borderDash: [8, 4]
          },
          ticks: {
            max: 24,
            stepSize: 4,
            maxTicksLimit: 10,
            reverse: true,
            callback: function(value: number, index: any, values: any) {
              if (value == 0 || value == 24) {
                return '12 am';
              } else if (value == 12) {
                return value + ' pm';
              } else if (value > 12) {
                return value - 12 + ' pm';
              } else {
                return value + ' am';
              }
            }
          },
          stacked: true
        }
      ],
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display: false
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 12,
            maxRotation: 0,
            minRotation: 0
          }
        }
      ]
    },
    plugins: {
      datalabels: {
        display: false
      }
    }
  } as ChartOptions;

  mounted() {
    this.endDay = this.day;
    this.timePeriod = this.getTimePeriod();
    if (this.selectedVatId) {
      this.updateData(true);
    }
  }

  @Watch('selectedVatId')
  selectedUpdated() {
    this.endDay = this.day;
    this.offset = 0;
    this.timePeriod = this.getTimePeriod();
    this.disable =
      dayjs(this.endDay).format('YYYYMMDD') ==
      dayjs(this.day).format('YYYYMMDD');
    this.updateData(false);
  }

  public initChart() {
    this.createChart({
      labels: this.getLabels(),
      datasets: this.getData()
    });
  }

  public initBlankChart() {
    this.createChart({
      labels: this.getBlankLabels(),
      datasets: [
        {
          label: 'Am',
          data: [],
          stack: '1',
          backgroundColor: this.colors[0]
        },
        {
          label: 'Pm',
          data: [],
          stack: '1',
          backgroundColor: this.colors[1]
        }
      ]
    });
  }

  public showtimeGraphModal() {
    if (!this.disabled) {
      this.$root.$emit('bv::show::modal', 'timeGraphModal', '#btnShow');
    }
  }

  public createChart(chartData: object) {
    const canvas: HTMLCanvasElement = this.$refs[
      `milkingTimeBar-${this.selectedVatId}-${this.location}`
    ] as HTMLCanvasElement;
    if (canvas) {
      const options: ChartConfiguration = {
        type: 'bar',
        data: chartData,
        options: this.graphOptions
      };
      this.chart = new Chart(canvas, options);
    }
  }

  public getTime(value: any): string[] {
    let times = value.yLabel;
    let duration;
    if (value.datasetIndex) {
      times =
        this.getAmPm(this.pm[value.index][0]) +
        ' - ' +
        this.getAmPm(this.pm[value.index][1]);
      duration = this.getDuration(
        this.pm[value.index][0],
        this.pm[value.index][1]
      );
    } else {
      times =
        this.getAmPm(this.am[value.index][0]) +
        ' - ' +
        this.getAmPm(this.am[value.index][1]);
      duration = this.getDuration(
        this.am[value.index][0],
        this.am[value.index][1]
      );
    }

    return [times, duration];
  }

  public getAmPm(time: number): string {
    const round = Math.floor(time);
    if (time == 0 || time == 24) {
      return '12:00 am';
    } else if (round == 12) {
      return 12 + ':' + time.toFixed(2).split('.')[1] + ' pm';
    } else if (time > 12) {
      return round - 12 + ':' + time.toFixed(2).split('.')[1] + ' pm';
    } else {
      return round + ':' + time.toFixed(2).split('.')[1] + ' am';
    }
  }

  public getDuration(start: number, finish: number) {
    const secondsFinish =
      Math.floor(finish) * 60 + +finish.toFixed(2).split('.')[1];
    const secondsStart =
      Math.floor(start) * 60 + +start.toFixed(2).split('.')[1];
    const minutesDuration = secondsFinish - secondsStart;
    const minutes = minutesDuration % 60;
    const hours = Math.floor(minutesDuration / 60);
    return (
      hours +
      (hours === 1 ? ' hour ' : ' hours ') +
      minutes +
      (minutes === 1 ? ' minute' : ' minutes')
    );
  }

  public updateData(initialise: boolean) {
    this.loading = true;
    FetchProductsCombinedGraphData(
      this.selectedProduct.supplierNumber.toString(),
      this.selectedProduct.supplier?.toString(),
      this.selectedVatId.toString(),
      dayjs(this.startFromDate())
        .unix()
        .toString(),
      dayjs(this.endDay)
        .unix()
        .toString(),
      22,
      this.offset,
      this.disabled,
      'Milking Time Graph'
    ).then(data => {
      if (data.length == 0) {
        this.noData = true;
        try {
          this.graphData = data;
          if (initialise || !this.chart) {
            this.initBlankChart();
          } else {
            this.chart.data.labels = this.getBlankLabels();
            this.chart.data.datasets = [
              {
                label: 'Am',
                data: [],
                stack: '1',
                backgroundColor: this.colors[0]
              },
              {
                label: 'Pm',
                data: [],
                stack: '1',
                backgroundColor: this.colors[1]
              }
            ];
            this.chart.options = this.graphOptions;
            this.chart.update();
          }
        } catch {
          console.error('Internal error');
        }
      } else {
        this.noData = false;
        try {
          this.graphData = data;
          if (initialise || !this.chart) {
            this.initChart();
          } else {
            this.chart.data.labels = this.getLabels();
            this.chart.data.datasets = this.getData();
            this.chart.options = this.graphOptions;
            this.chart.update();
          }
        } catch {
          console.error('Internal error');
        }
      }
      this.loading = false;
    });
  }

  public getData(): ChartDataSets[] {
    const values = this.fetchProductsGraphSampleData();
    const grouped = Array.from(groupBy(values, (value: any) => value.day));
    const am = grouped.map((dayEntry: Array<any>) => {
      let amTime = [0, 0];
      dayEntry[1].forEach((value: any) => {
        if (!value.pm) {
          amTime = value.milkingTime;
        }
      });
      return amTime;
    });
    const pm = grouped.map((dayEntry: Array<any>) => {
      let pmTime = [0, 0];
      dayEntry[1].forEach((value: any) => {
        if (value.pm) {
          pmTime = value.milkingTime;
        }
      });
      return pmTime;
    });
    const alteredAm = am.map((dec: number[]) => {
      return [this.convertToDecimal(dec[0]), this.convertToDecimal(dec[1])];
    });
    const pmDecimal = pm.map((dec: number[]) => {
      return [this.convertToDecimal(dec[0]), this.convertToDecimal(dec[1])];
    });
    const alteredPm = pmDecimal.map((item, index) => {
      return item[0] != 0
        ? [item[0] - alteredAm[index][1], [item[1] - alteredAm[index][1]]]
        : item;
    });
    this.am = am;
    this.pm = pm;
    return [
      {
        label: 'Am',
        data: alteredAm,
        stack: '1',
        backgroundColor: this.colors[0]
      },
      {
        label: 'Pm',
        data: alteredPm as number[][],
        stack: '1',
        backgroundColor: this.colors[1]
      }
    ];
  }

  public getLabels(): string[] {
    const prod = Array.from(
      new Set(
        this.fetchProductsGraphSampleData().map((entry: any) => {
          return entry.date;
        })
      )
    );
    return prod as string[];
  }

  public getBlankLabels(): string[] {
    const labels = [];
    for (let i = 0; i <= 20; i++) {
      labels.push(
        this.startFromDate()
          .add(i, 'day')
          .format('DD')
      );
    }
    return labels;
  }

  public getTimePeriod(): string {
    const startFrom = this.startFromDate();

    return startFrom.format('DD MMM - ') + dayjs(this.endDay).format('DD MMM');
  }

  public convertToDecimal(time: number): number {
    const hour = Math.floor(time);

    return hour + (time - hour) / 0.6;
  }

  public startFromDate() {
    return dayjs(this.endDay).subtract(20, 'day');
  }

  public changeTimePeriod(direction: string) {
    this.loading = true;
    if (direction == 'left') {
      this.endDay = this.startFromDate().format('YYYY-MM-DD');

      this.timePeriod = this.getTimePeriod();
    } else {
      this.endDay = dayjs(this.endDay)
        .add(20, 'day')
        .format('YYYY-MM-DD');

      this.timePeriod = this.getTimePeriod();
    }
    this.offset = dayjs().diff(dayjs(this.endDay), 'day');
    this.offset = this.offset == 0 ? 0 : this.offset - 1;

    this.disable =
      dayjs(this.endDay).format('YYYYMMDD') ==
      dayjs(this.day).format('YYYYMMDD');
    this.updateData(false);
  }

  public fetchProductsGraphSampleData(): any {
    const products = this.graphData.map((entry: any) => {
      const finish = dayjs.unix(entry.finishTime);
      const start = dayjs.unix(entry.startTime);
      return {
        date: finish.format('DD'),
        milkingTime: [+start.format('HH.mm'), +finish.format('HH.mm')],
        day: +finish.format('DD'),
        pm: finish.isBefore(finish.format('YYYY-MM-DD') + '12:00') ? 0 : 1
      };
    });
    return products;
  }
}
