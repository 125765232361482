
import FormDropdownInput from '@/components/common/FormDropdownInput.vue';
import FormTextInput from '@/components/common/FormTextInput.vue';
import { UpdateCustomSettingsData } from '@/services/api/CustomSettingApi';
import { trackEvent } from '@/services/Mixpanel';
import { ComplianceSettings } from '@/store/models/MilkScore';
import { Product } from '@/store/models/Product';
import CustomStore from '@/store/modules/CustomStore';
import ProductStore from '@/store/modules/ProductStore';
import User from '@/store/modules/User';
import { complianceMethodLabel, getMethod } from '@/utils/MilkScoreUtils';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';

@Component({
  components: {
    FormTextInput,
    FormDropdownInput
  },
  methods: {
    complianceMethodLabel
  }
})
export default class ComplianceMethodSelection extends mixins(AppName) {
  public loading = false;
  get currentProduct(): Product | null {
    return ProductStore.currentProduct ?? null;
  }

  get complianceMethods() {
    return CustomStore.complianceMethods;
  }

  get currentMethod() {
    return getMethod(
      (this.currentProduct?.entityCustomSettingsData as ComplianceSettings) ??
        null
    );
  }

  @Watch('currentProduct', { deep: true })
  productUpdated() {
    this.loading = false;
  }

  public isSelected(method: string) {
    return method == this.currentMethod;
  }

  public updateSelection(method: ComplianceSettings) {
    this.loading = true;
    const vatsToUpdate: Promise<any>[] = [];
    this.currentProduct?.entities?.forEach(vat => {
      vatsToUpdate.push(
        UpdateCustomSettingsData({
          partnerId: User.orgId,
          entityType: this.getEntityApiWord(),
          entityId: +(vat.entityId ?? 0),
          data: method
        })
      );
    });
    Promise.all(vatsToUpdate)
      .then(() => {
        this.$root.$emit('customFieldsUpdated');
      })
      .catch(() => {
        this.loading = false;
      });

    trackEvent('User changed compliance method', {
      method: method.complianceMethod
    });
  }
}
